import React from 'react';
import { useSelector } from 'react-redux';
import Image from '../../../../Image/Image';
import { markenStandortSelector } from '../../../../../../modules/selectors/standort/markenStandort';
import { InternalLink } from '../../../../form-elements/Link';

function Logo() {
    const markenStandort = useSelector(markenStandortSelector);
    return (
        <InternalLink to="/">
            <Image
                src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Logo.jpg`}
                alt={`${markenStandort.title} Logo`}
                resizeHeight={70}
                resizeWidth={240}
            />
        </InternalLink>
    );
}

export default Logo;
